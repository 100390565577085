import { Component, OnInit, Inject } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { DOCUMENT } from '@angular/common';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  guids: UUID[] = [];
  constructor(@Inject(DOCUMENT) private document: Document) {
    this.guids.push(UUID.UUID());
  }

  newguid() {
    this.guids.push(UUID.UUID());
  }
  ngOnInit(): void {
  }
  onCopy(idx: string) {
    const el = document.getElementById(idx);
    el.setAttribute('contenteditable', 'true');
    el.focus();
    document.execCommand('selectAll');
    document.execCommand('copy');
    el.setAttribute('contenteditable', 'false');
    el.blur();
  }

}
